.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.page-contents {
  width: 800px;
}

@media screen and (max-width: 768px) {
  .page-contents {
    width: 90vw;
  }
}
