.tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  height: 50px;
  padding: 0 10px;
  margin-bottom: 6px;
  background: white;
  color: black;
  text-decoration: none;
}

.tile-header {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .tile {
    width: 80vw;
  }
}
