@import url('https://fonts.googleapis.com/css?family=Open+Sans');
.app {
  font-size: 100%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.content {
  display: flex;
  justify-content: center;
  align-content: center;
}
