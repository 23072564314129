.button {
  display: block;
  margin: 20px auto;
  padding: 16px 32px;
  color: white;
  border: none;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.button-green {
  background-color: #3fb843;
}
