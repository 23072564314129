@import url(https://fonts.googleapis.com/css?family=Open+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f9f9f9;
}

.app {
  font-size: 100%;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.content {
  display: flex;
  justify-content: center;
  align-content: center;
}

.menu {
  display: flex;
  width: 100%;
  height: 50px;
  font-family: "Open Sans", sans-serif;
  background: #333333;
}

.menu ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu li {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.menu li:hover  {
  background: #444444;
}

.menu li a {
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-decoration: none;
  color: #999999;
}

.menu li:hover a {
  color: white;
}

.phone {
    width: auto;
    height: 87.5vh;
    padding: 20px;
}

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.page-contents {
  width: 800px;
}

@media screen and (max-width: 768px) {
  .page-contents {
    width: 90vw;
  }
}

.tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 800px;
  height: 50px;
  padding: 0 10px;
  margin-bottom: 6px;
  background: white;
  color: black;
  text-decoration: none;
}

.tile-header {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .tile {
    width: 80vw;
  }
}

.button {
  display: block;
  margin: 20px auto;
  padding: 16px 32px;
  color: white;
  border: none;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
}

.button-green {
  background-color: #3fb843;
}

textarea {
  resize: none;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.label-above {
  display: block;
  margin-bottom: 5px;
}

.radio-group {
  margin: 5px;
}

.date-picker {
  margin: 15px;
}

@media screen and (max-width: 768px) {
  #message {
    width: 90%;
  }
  .button {
    width: 90%;
  }
}

