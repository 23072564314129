.menu {
  display: flex;
  width: 100%;
  height: 50px;
  font-family: "Open Sans", sans-serif;
  background: #333333;
}

.menu ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu li {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.menu li:hover  {
  background: #444444;
}

.menu li a {
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-decoration: none;
  color: #999999;
}

.menu li:hover a {
  color: white;
}
