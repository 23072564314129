textarea {
  resize: none;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.label-above {
  display: block;
  margin-bottom: 5px;
}

.radio-group {
  margin: 5px;
}

.date-picker {
  margin: 15px;
}

@media screen and (max-width: 768px) {
  #message {
    width: 90%;
  }
  .button {
    width: 90%;
  }
}
